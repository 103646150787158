$primary: #29457f;
$secondary: #f9f871;
$light: #f7f7f7;

$border-radius-lg: 1rem;

@import 'bootstrap/bootstrap';

img {
	width: 100%;
	max-width: 100%;
	height: auto;
}


.thumbnail {
	display: block;
	overflow: hidden;
	height: 0;
	padding-top: 100%;
	background-size: cover;
	background-position: 50%;
}


.banner {
	color: $white;
	

}

.navbar {
	padding: 1rem 0;
}


.logo, .banner, nav.breadcrumb-container {
	background: $primary;
	background: linear-gradient(-90deg, rgba(0,185,236,1) 0%, rgba(41,61,118,1) 100%);
}



.banner {
    position: relative;
	width: 100%;
	padding: 6rem 0;
	margin-bottom: 4rem;
	text-align: center;
}

.banner:before {
	content: "";
	background-image: url('/images/banner.jpg');
	background-size: cover;
	background-position: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0.1;
}

.banner .container {
	position: relative;
}



article header {
}

article h1 {
	font-size: 2rem;
}


.banner .cover {
	width: 100%;
	padding: 6rem 0;
	margin-bottom: 4rem;
	text-align: center;
	
}

.banner h1 {
	font-size: 3rem;
}

.banner p {
	margin: 2rem auto;
	max-width: 50em;
}

.logo {
	height: 2.5rem;
	width: auto;
}

footer {
	padding-top: 3rem;
	padding-bottom: 3rem;
	background: #f7f7f7;
}

footer p {
	margin-bottom: .25rem;
}

footer h4 {
	font-weight: bold;
}

footer ul {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
}

footer .logo {
	background: transparent;
	height: 2rem;
}

.breadcrumb-container {
	padding: 0.5rem 0;
}

.breadcrumb-container ol {
	margin-bottom: 0;
}

.breadcrumb, .breadcrumb-item.active, .breadcrumb-item::before {
	background: transparent;
	color: $white !important;
}

.breadcrumb-item a, .breadcrumb-item a:hover, .breadcrumb-item a:visited {
	color: $white;
	text-decoration: underline;
}

i.lithium {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	
	font-style: normal;
	border: 0.125rem solid $white;
	border-radius: 10%;
	height: 2rem;
	width: 2rem;
	font-size: 0.75em;
}

i.lithum::after {
	color: $white;
	content: "Li";
}

li.active {
	font-weight: bold;
}

.row.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}



.contact-us {
	background-image: url('/images/contact-us.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.about-us {
	background-image: url('/images/about-us.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

h1, h2, h3, h4, h5, h6 {
	word-break: normal;
	overflow-wrap: anywhere;
}
